import React, { FC } from 'react';
import classNames from 'classnames';

import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import ProductClass from 'common/ProductClass';

import { IPropsCheckBoxCustom } from './models';

import './CheckBoxCustom.scss';

const CheckBoxCustom: FC<IPropsCheckBoxCustom> = ({
  isChecked,
  isDisabled,
  id,
  name,
  labelText,
  rteTitle,
  onChange,
  labelIcon,
  labelIconBgColor,
}) => (
  <div
    data-test="CheckBoxCustom"
    className={classNames('checkbox-custom', {
      'checkbox-custom--disabled': isDisabled,
    })}
  >
    <input
      className="checkbox-custom__input"
      type="checkbox"
      checked={isChecked}
      onChange={onChange}
      id={`checkbox-custom-${id}`}
      name={name}
    />
    <label htmlFor={`checkbox-custom-${id}`}>
      <span className="checkbox-custom__fake-input">
        <span className="checkbox-custom__fake-input__sign" />
      </span>
      {rteTitle && labelIcon && labelIconBgColor ? (
        <ProductClass label={rteTitle} icon={labelIcon} bgColor={labelIconBgColor} />
      ) : (
        <DangerouslySetInnerHtml html={labelText} className="checkbox-custom__fake-label" />
      )}
    </label>
  </div>
);

export default CheckBoxCustom;
